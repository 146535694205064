<!-- <div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span id="title" class="title dialog-title">{{name}}</span>
            <span id="job" class="subtitle dialog-title">{{ jobTitle }}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <img [src]="imageUrl" class=" huge m-0" [alt]="name" />
</div> -->

<div class="dialog-content-wrapper">
    <div class="close-button">
        <button class="accent" mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>
        <img [src]="imageUrl" onerror="this.src='/assets/images/avatars/profile.jpg'">
        <div class="p-16">
            <div class="h1">{{name}}</div>
            <div class="h4 secondary-text">{{jobTitle}}</div>
        </div>
        <div class="notes p-12 pt-0 line-height-1.5">
            {{notes}}
        </div>
    </div>



</div>