<mat-toolbar class="p-0 mat-elevation-z1">
  <div
    fxFlex
    fxFill
    fxLayout="row"
    fxLayoutAlign="start center"
    class="accent-50"
  >
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <!-- se quitó     fxHide.gt-md para que se pudiese ver en cualquier momento  -->
      <button
        mat-icon-button
        class="navbar-toggle-button"
        *ngIf="isSmallScreen"
        (click)="toggleSidebarOpen('navbar-main')"
      >
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>

      <button
        mat-icon-button
        class="navbar-toggle-button"
        *ngIf="!isSidebarLocked && !isSmallScreen"
        (click)="toggleSidebarOpen('navbar-main')"
      >
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>

      <!--  Se quitó para que se pueda ver en cualquier momento  fxHide.gt-md -->
      <div class="toolbar-separator" *ngIf="!sidebarOpen"></div>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
        <div class="logo ml-16">
          <img class="logo-icon" src="assets/images/logos/fuse.svg" />
        </div>
      </div>

      <div class="px-8 px-md-16">
        <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
      </div>
    </div>

    <div class="toolbar-separator" fxHide.gt-md></div>

    <button
      *ngIf="!_auth.isDefault"
      mat-icon-button
      class="quick-panel-toggle-button"
      (click)="updateDefaultPage()"
      [matTooltip]="'layout.toolbar.defaultPageTooltip' | translate"
      aria-label="Establecer página por defecto"
    >
      <mat-icon class="secondary-text">bookmark_border</mat-icon>
    </button>

    <button
      *ngIf="_auth.isDefault"
      mat-icon-button
      class="quick-panel-toggle-button"
      (click)="updateDefaultPage()"
      [matTooltip]="'layout.toolbar.defaultPageTooltip' | translate"
      aria-label="Establecer página por defecto"
    >
      <mat-icon class="material-icons color_green">bookmark</mat-icon>
    </button>

    <div class="toolbar-separator" fxHide.gt-md></div>

    <button
      *ngIf="notifications.notifications.length === 0"
      mat-icon-button
      class="quick-panel-toggle-button"
      (click)="toggleSidebarOpen('quickPanel')"
      aria-label="Toggle quick panel"
    >
      <mat-icon class="secondary-text">notifications</mat-icon>
    </button>
    <button
      *ngIf="notifications.notifications.length > 0"
      mat-icon-button
      class="quick-panel-toggle-button"
      (click)="toggleSidebarOpen('quickPanel')"
      aria-label="Toggle quick panel"
    >
      <mat-icon
        [matBadge]="notifications.notifications.length"
        color="primary"
        matBadgeColor="warn"
        matBadgePosition="above after"
        >notifications_active</mat-icon
      >
    </button>

    <button
      mat-button
      class="language-button"
      [matMenuTriggerFor]="languageMenu"
      *ngIf="allowSetLanguage"
    >
      <div fxLayout="row" fxLayoutAlign="center center">
        <img
          class="flag mr-8"
          [src]="'assets/icons/flags/' + selectedLanguage.flag + '.png'"
        />
        <span class="iso text-uppercase">{{ selectedLanguage.id }}</span>
      </div>
    </button>

    <div
      class="accent-50"
      fxFlex="0 1 auto"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
        <div fxLayout="row" fxLayoutAlign="center center">
          <img
            class="avatar mr-0 mr-sm-16"
            [src]="_auth.userImageUrl"
            onerror="this.src='/assets/images/avatars/profile.jpg'"
          />
          <span class="username mr-12 secondary-text" fxHide fxShow.gt-sm>{{
            _auth.user.name + " " + _auth.user.lastName || ""
          }}</span>
          <mat-icon class="s-16 secondary-text" fxHide.xs
            >keyboard_arrow_down</mat-icon
          >
        </div>
      </button>
      <mat-menu #userMenu="matMenu" [overlapTrigger]="false" class="accent-50">
        <button mat-menu-item (click)="viewProfile()">
          <mat-icon>account_circle</mat-icon>
          <span>{{ "layout.toolbar.miPerfil" | translate }}</span>
        </button>

        <button mat-menu-item class="" (click)="viewAbout()">
          <mat-icon>info</mat-icon>
          <span>{{ "layout.toolbar.about" | translate }}</span>
        </button>

        <button
          mat-menu-item
          class=""
          (click)="changePassword()"
          *ngIf="authType === 'application'"
        >
          <mat-icon>lock</mat-icon>
          <span>{{ "layout.toolbar.cambiarPwd" | translate }}</span>
        </button>

        <hr />
        <button mat-menu-item class="" (click)="closeSession()">
          <mat-icon>exit_to_app</mat-icon>
          <span>{{ "layout.toolbar.cerrarSesion" | translate }}</span>
        </button>
      </mat-menu>

      <div class="toolbar-separator"></div>

      <!-- <fuse-search-bar (input)="search($event)"></fuse-search-bar> -->

      <!-- <div class="toolbar-separator"></div>
    
                <button mat-button fxHide fxShow.gt-xs class="language-button" [matMenuTriggerFor]="languageMenu">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
                        <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
                    </div>
                </button> -->

      <mat-menu #languageMenu="matMenu">
        <button
          mat-menu-item
          *ngFor="let lang of languages"
          (click)="setLanguage(lang)"
        >
          <span fxLayout="row" fxLayoutAlign="start center">
            <img
              class="flag mr-16"
              [src]="'assets/icons/flags/' + lang.flag + '.png'"
            />
            <span class="iso">{{ lang.title }}</span>
          </span>
        </button>
      </mat-menu>

      <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

      <div
        class="toolbar-separator"
        *ngIf="!hiddenNavbar && rightNavbar"
        fxHide
        fxShow.gt-xs
      ></div>

      <button
        mat-icon-button
        class="navbar-toggle-button"
        *ngIf="!hiddenNavbar && rightNavbar"
        (click)="toggleSidebarOpen('navbar-main')"
        fxHide.gt-md
      >
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
