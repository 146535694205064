import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
    AfterViewInit,
    ElementRef,
    HostListener,
  } from "@angular/core";
  import { NavigationEnd, Router } from "@angular/router";
  import { Subject, Subscription } from "rxjs";
  import { delay, filter, take, takeUntil } from "rxjs/operators";
  import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
  import { FuseConfigService } from "@fuse/services/config.service";
  import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
  import { FusePerfectScrollbarDirective } from "@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";

  import { AuthService } from "app/main/services/auth.service";
  import { MatDialog } from "@angular/material/dialog";
  import { AvatarViewerDialogComponent } from "app/main/components/avatar-viewer/avatar-viewer.component";
  import { FunctionsService } from "../../../../../main/services/functions.service";
  import { FuseSidebarComponent } from "@fuse/components/sidebar/sidebar.component";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
  
  @Component({
    selector: "navbar-vertical-style-1",
    templateUrl: "./style-1.component.html",
    styleUrls: ["./style-1.component.scss"],
    encapsulation: ViewEncapsulation.None,
  })
  export class NavbarVerticalStyle1Component implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    dialogAvatarViewer: any;
    isSidebarLocked: boolean = false;
    private breakpointSubscription: Subscription;
    // Private
    private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;
      isSmallScreen: boolean;
  
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {Router} _router
     */
    constructor(
      private _el: ElementRef,
      private _fuseConfigService: FuseConfigService,
      private _fuseNavigationService: FuseNavigationService,
      private _fuseSidebarService: FuseSidebarService,
      private _router: Router,
      public _auth: AuthService,
      private _matDialog: MatDialog,
      private _functions: FunctionsService,
      private breakpointObserver: BreakpointObserver
    ) {
      // Set the private defaults
      this._unsubscribeAll = new Subject();
      this.breakpointSubscription = this.breakpointObserver
    .observe([
      Breakpoints.Small,
      Breakpoints.XSmall,
      Breakpoints.Large,
      Breakpoints.Medium,
      Breakpoints.XLarge
    ])
    .subscribe((result) => {
      if (result.matches) {
        if (result.breakpoints[Breakpoints.Small] || result.breakpoints[Breakpoints.XSmall]) {
          // Handle small screen conditions
          this.isSmallScreen = true;
          this.isSidebarLocked = false;
          this._fuseSidebarService.getSidebar("navbar-main").isLockedOpen = false;
        } else {
          // Handle larger screen conditions
          this.isSmallScreen = false;
          this.isSidebarLocked = false; // Set to true if needed
          this._fuseSidebarService.getSidebar("navbar-main").close();
          this._fuseSidebarService.setIsSidebarLocked(false);
        }
      }
    });
    }
  
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------
  
    // Directive
    @ViewChild(FusePerfectScrollbarDirective, { static: true })
    set directive(theDirective: FusePerfectScrollbarDirective) {
      if (!theDirective) {
        return;
      }
  
      this._fusePerfectScrollbar = theDirective;
  
      // Update the scrollbar on collapsable item toggle
      this._fuseNavigationService.onItemCollapseToggled
        .pipe(delay(500), takeUntil(this._unsubscribeAll))
        .subscribe(() => {
          this._fusePerfectScrollbar.update();
        });
  
      // Scroll to the active item position
      this._router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          take(1)
        )
        .subscribe(() => {
          setTimeout(() => {
            this._fusePerfectScrollbar.scrollToElement(
              "navbar .nav-link.active",
              -120
            );
          });
        });
    }
  
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
  
    /**
     * On init
     */
    ngOnInit(): void {

      this._router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          takeUntil(this._unsubscribeAll)
        )
        .subscribe(() => {
          if (this._fuseSidebarService.getSidebar("navbar-main")) {
            this._fuseSidebarService.getSidebar("navbar-main").close();
          }
        });
  
      // Subscribe to the config changes
      this._fuseConfigService.config
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((config) => {
          this.fuseConfig = config;
        });
  
      // Get current navigation
      this._fuseNavigationService.onNavigationChanged
        .pipe(
          filter((value) => value !== null),
          takeUntil(this._unsubscribeAll)
        )
        .subscribe(() => {
          this.navigation = this._fuseNavigationService.getCurrentNavigation();
        });
    }
  
    /**
     * On destroy
     */
    ngOnDestroy(): void {
      // Unsubscribe from all subscriptions
      if (this.breakpointSubscription) {
        this.breakpointSubscription.unsubscribe();
      }
      this._unsubscribeAll.next("");
      this._unsubscribeAll.complete();
    }

    

    closeSidebarOnClickOutside(event: MouseEvent): void {
      const target = event.target as HTMLElement;
      const sidebar: FuseSidebarComponent = this._fuseSidebarService.getSidebar('navbar-main');
      let isOtherSidebarOpened = false;
    
     
    
      const isOutsideSidebar = !this._el.nativeElement.contains(target);
      const isMenuButton = target.closest('.navbar-toggle-button');
      const isFormMainSidebar = target.closest('.form-main-sidebar');
    
      if (sidebar.opened) {
        if (isOutsideSidebar && !isMenuButton  && !isFormMainSidebar) {
          this.toggleSidebarOpened();
       
        }
      } else {
      
       
      }
    }
    closeOtherSidebars(event: MouseEvent): void {
      const target = event.target as HTMLElement;
      let hasReachedCondition = false;
      let isOtherSidebarOpened = false;
    
      this._fuseSidebarService._sidebarKeys.forEach((sidebarKey) => {
        const sidebar: FuseSidebarComponent = this._fuseSidebarService.getSidebar(sidebarKey);
        if(sidebarKey !== 'navbar-main') { 
        if (sidebar && sidebar.opened) {
          const isFormMainSidebar = target.closest('.form-main-sidebar');
          const isMenuButton = target.closest('button.sidebar-toggle');
        
          if (!isFormMainSidebar && !isMenuButton ) {
            this._fuseSidebarService.closeSpecificSidebar(sidebarKey);
          } else {
            isOtherSidebarOpened = true;
          }
        }
        }
      });
    
    }
    

  
    @HostListener("document:click", ["$event"])
    onDocumentClick(event: MouseEvent): void {
      this.closeSidebarOnClickOutside(event);
      this.closeOtherSidebars(event)
    }
  
  
    callAvatarViewer(): void {
      this.dialogAvatarViewer = this._matDialog.open(
        AvatarViewerDialogComponent,
        {
          panelClass: "avatar-viewer-dialog",
          data: {
            imageUrl: this._auth.userImageUrl,
            name: this._auth.user.name + " " + this._auth.user.lastName,
            jobTitle: this._auth.user.jobTitle,
            notes: this._auth.user.notes,
          },
        }
      );
    }
  
    gotoMain() {
      this._auth.redirectToDefaultFeature();
    }
  
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
  
    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpened(): void {
        const mainSidebar = this._fuseSidebarService.getSidebar("navbar-main");
      
        if (mainSidebar.opened) {
          this.updateSetting("false");
        } else {
          this.updateSetting("true");
        }
      
        mainSidebar.toggleOpen();
      }
      
  
    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void {
  
      // Controla que se bloquee el navbar 
      this.isSidebarLocked = !this.isSidebarLocked;
      this._fuseSidebarService.setIsSidebarLocked(this.isSidebarLocked);
      const sidebar = this._fuseSidebarService.getSidebar('navbar-main');
      sidebar.isLockedOpen = this.isSidebarLocked;

      
  
      // Controlar la visibilidad del botón "arrow_back"
      const arrowBackButton = document.querySelector('.toggle-sidebar-opened');
      if (arrowBackButton) {
          if (this.isSidebarLocked) {
              arrowBackButton.classList.add('hidden');
              this.toggleSidebarOpened()
          } else {
              arrowBackButton.classList.remove('hidden');
          }
      }
  }
  
  
    updateSetting(value) {
      this._auth.updateUserSetting("hidden-sidebar", value);
    }
  }
  