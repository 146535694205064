<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{ dialogTitle }}</span>
      <button
        mat-icon-button
        (click)="matDialogRef.close()"
        aria-label="Close dialog"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>

    <mat-toolbar-row
      class="toolbar-bottom py-16"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <img
        [src]="_auth.userImageUrl"
        onerror="this.src='/assets/images/avatars/profile.jpg'"
        class="avatar contact-avatar huge m-0"
        [alt]="contact.name"
        (click)="callAvatarViewer()"
      />
      <div class="contact-name mt-8">
        {{ contact.name }} {{ contact.lastName }}
      </div>
      <!-- ADD CAMERA BUTTON -->
      <div
        class="accent-400 profile-camera-button"
        (click)="fileInput.click()"
        aria-label="add contact"
        [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
      >
        <mat-icon class="_mat-icon">camera_alt</mat-icon>
        <input
          hidden
          type="file"
          accept=".gif,.jpg,.jpeg,.png"
          (change)="handleFileInput($event.target.files)"
          #fileInput
        />
      </div>
      <!-- / ADD CAMERA BUTTON -->
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>
    <form [formGroup]="contactForm">
      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label> {{ "security.myprofile.nombre" | translate }} </mat-label>
          <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
          <input name="name" formControlName="name" matInput required />
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>
            {{ "security.myprofile.apellido" | translate }}</mat-label
          >
          <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
          <input name="lastName" formControlName="lastName" matInput />
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label> {{ "security.myprofile.correo" | translate }} </mat-label>
          <mat-icon matSuffix class="secondary-text">email</mat-icon>
          <input
            name="email"
            formControlName="email"
            matInput
            type="email"
            required
          />
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{ "security.myprofile.id" | translate }}</mat-label>
          <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
          <input
            name="username"
            formControlName="username"
            matInput
            required
            [disabled]="!disabled"
          />
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{ "security.myprofile.nickName" | translate }}</mat-label>
          <mat-icon matSuffix class="secondary-text">star</mat-icon>
          <input name="nickname" formControlName="nickname" matInput />
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label
            >{{ "security.myprofile.telefono" | translate }}
          </mat-label>
          <mat-icon matSuffix class="secondary-text">phone</mat-icon>
          <input formControlName="phone" matInput />
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{ "security.myprofile.cargo" | translate }}</mat-label>
          <mat-icon matSuffix class="secondary-text">work</mat-icon>
          <input name="jobTitle" formControlName="jobTitle" matInput />
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{ "security.myprofile.birthday" | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="birthdayDatePicker"
            name="birthday"
            formControlName="birthday"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="birthdayDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #birthdayDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label
            >{{ "security.myprofile.direccion" | translate }}
          </mat-label>
          <mat-icon matSuffix class="secondary-text">home</mat-icon>
          <input name="address" formControlName="address" matInput />
        </mat-form-field>
      </div>

      <div fxLayout="row" class="textarea-wrapper" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label
            >{{ "security.myprofile.acercade" | translate }} Acerca de
            mi</mat-label
          >
          <textarea
            name="notes"
            formControlName="notes"
            matInput
            type="text"
            max-rows="10"
          ></textarea>
        </mat-form-field>
      </div>
    </form>
  </div>

  <div
    mat-dialog-actions
    class="m-0 p-16"
    fxLayout="row"
    fxLayoutAlign="end center"
  >
    <button
      mat-button
      class="save-button"
      (click)="matDialogRef.close([contactForm])"
      [disabled]="contactForm.invalid"
      aria-label="SAVE"
    >
      {{ "security.myprofile.botonGuardar" | translate }}
    </button>
  </div>
</div>
