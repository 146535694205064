<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{ "about.title" | translate }}</span>
      <button
        mat-icon-button
        (click)="matDialogRef.close()"
        aria-label="Close dialog"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-4 pb-0 m-0" fusePerfectScrollbar>
    <div class="logo">
      <img
        style="border-radius: 10px"
        src="assets/images/logos/logo_software.png"
      />
    </div>
    <div class="app-title">
      <h1>{{ aboutInfo.application.name }}</h1>
    </div>
    <div class="app-title">
      <h3>{{ aboutInfo.application.version }}</h3>
    </div>
    <p
      style="
        padding-left: 20px !important;
        padding-right: 20px !important;
        text-align: justify;
      "
    >
      {{ aboutInfo.application.description }}
    </p>
    <hr />
    <div class="app-title">
      <h3>{{ "about.dependences" | translate }}</h3>
    </div>

    <ul>
      <li *ngFor="let item of dependences">
        <span style="font-weight: bold">{{ item.name }}</span>
        <span style="font-weight: lighter; font-size: 13px"
          >, {{ item.version }}</span
        >
        <br />
        <span style="font-weight: lighter; font-size: 12px">{{
          item.description
        }}</span>
      </li>
    </ul>
  </div>
  <div
    mat-dialog-actions
    class="m-0 p-16"
    fxLayout="row"
    fxLayoutAlign="end center"
  ></div>
</div>
