import { Injectable, OnDestroy, OnInit } from "@angular/core";

import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";

import { locale as _ingles } from "./../../navigation/i18n/en";
import { locale as _frances } from "./../../navigation/i18n/fr";
import { locale as _espanol } from "./../../navigation/i18n/es";
import { locale as _aleman } from "../../navigation/i18n/de";
import { locale as _portugues } from "../../navigation/i18n/pt";
import { locale as _italiano } from "../../navigation/i18n/it";

import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "./auth.service";
import { SocketService } from "./socket.service";
import { takeUntil } from "rxjs/operators";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AppTranslateService implements OnDestroy {
  espanol: any;
  ingles: any;
  frances: any;
  aleman: any;
  portugues: any;
  italiano: any;

  languages: any = [];
  defaultLanguage: string;
  allowSetLanguage: boolean = true;
  onLanguageChanged: BehaviorSubject<any>;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translateService: TranslateService,
    private _auth: AuthService,
    private ws: SocketService
  ) {
    this._unsubscribeAll = new Subject();
    this.onLanguageChanged = new BehaviorSubject([]);
    this.loadLang();
  }

  loadLang = async () => {
    const es = await _espanol.data;
    const en = await _ingles.data;
    const de = await _aleman.data;
    const it = await _italiano.data;
    const fr = await _frances.data;
    const pt = await _portugues.data;

    this.espanol = { lang: _espanol.lang, data: es };
    this.ingles = { lang: _ingles.lang, data: en };
    this.aleman = { lang: _aleman.lang, data: de };
    this.frances = { lang: _frances.lang, data: fr };
    this.italiano = { lang: _italiano.lang, data: it };
    this.portugues = { lang: _portugues.lang, data: pt };

    this._fuseTranslationLoaderService.loadTranslations(
      this.ingles,
      this.frances,
      this.espanol,
      this.aleman,
      this.portugues,
      this.italiano
    );
    this.defaultLanguage = this._translateService.getDefaultLang();

    this.ws
      .listen("set-language")
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data: any) => {
        if (data.clientId === this.ws.user.id) {
          this.onLanguageChanged.next(data.lang);
        } else {
          if (data.user.user_id === this.ws.user.name) {
            this.setLanguage(data.lang, false);
            this.onLanguageChanged.next(data.lang);
          }
        }
      });
  };

  ngOnDestroy(): void {
    this._unsubscribeAll.next("");
    this._unsubscribeAll.complete();
  }

  setLanguage(lang: any, send: boolean = true) {
    let id: string = lang.id ? lang.id : lang;
    this._translateService.use(id);
    this.defaultLanguage = lang;
    if (send) {
      this._auth.setLanguage(lang);
    }
    this.saveDefaultInStorage(lang);
  }

  saveDefaultInStorage(lang) {
    localStorage.setItem("lang", JSON.stringify(lang));
  }

  setAllowSetLanguage(allow: boolean) {
    this.allowSetLanguage = allow || false;
  }

  translate(key) {
    return this._translateService.instant(key);
  }

  getLanguages() {
    this.languages = [
      {
        id: "es",
        title: "Español",
        flag: "es",
      },
      {
        id: "en",
        title: "English",
        flag: "us",
      },
      {
        id: "de",
        title: "Deutsch",
        flag: "de",
      },
      {
        id: "fr",
        title: "Français",
        flag: "fr",
      },
      {
        id: "it",
        title: "Italiano",
        flag: "it",
      },
      {
        id: "pt",
        title: "Português",
        flag: "pt",
      },
    ];
    return this.languages;
  }
}
