<div
  class="navbar-top"
  [ngClass]="fuseConfig.layout.navbar.secondaryBackground"
>
  <div class="logo hand" (click)="gotoMain()">
    <img class="logo-icon" src="assets/images/logos/favicon.ico" />
    <span class="logo-text secondary-text">Forms Application</span>
  </div>

  <div class="buttons">
    <button
      mat-icon-button
      class="toggle-sidebar-folded"
      (click)="toggleSidebarFolded()"
      fxHide.lt-lg
    >
      <mat-icon class="secondary-text">{{
        isSidebarLocked ? "location_off" : "location_on"
      }}</mat-icon>
    </button>

    <button
      *ngIf="!isSidebarLocked"
      mat-icon-button
      class="toggle-sidebar-opened"
      [class.hidden]="isSidebarLocked"
      (click)="toggleSidebarOpened()"
    >
      <mat-icon class="secondary-text">arrow_back</mat-icon>
    </button>
  </div>
</div>

<div
  class="navbar-scroll-container"
  [ngClass]="fuseConfig.layout.navbar.primaryBackground"
  fusePerfectScrollbar
  [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
>
  <div
    class="user"
    fxLayout="column"
    [ngClass]="fuseConfig.layout.navbar.secondaryBackground"
  >
    <div class="h3 username">
      {{ _auth.user.name + " " + _auth.user.lastName || "" }}
    </div>
    <div class="h5 email hint-text mt-8">{{ _auth.user.email || "" }}</div>
    <div
      class="avatar-container"
      [ngClass]="fuseConfig.layout.navbar.primaryBackground"
    >
      <img
        class="avatar"
        [src]="_auth.userImageUrl"
        onerror="this.src='/assets/images/avatars/profile.jpg'"
        (click)="callAvatarViewer()"
      />
    </div>
  </div>

  <div class="navbar-content">
    <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
  </div>
</div>
