import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";

import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialog } from "@angular/material/dialog";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { AuthService } from "app/main/services/auth.service";
import { AppTranslateService } from "app/main/services/translate.service";
import {
  MyProfileFormDialogComponent,
  PICK_FORMATS,
  PickDateAdapter,
} from "../my-profile/my-profile.component";
import { fuseAnimations } from "@fuse/animations";



@Component({
  selector: "about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  providers: [
    { provide: DateAdapter, useClass: PickDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS },
  ],
})
export class AboutDialogComponent implements OnInit {
  dialogTitle: string;
  disabled = false;
  group: string;
  dialogAvatarViewer: any;
  aboutInfo: any = {};
  dependences: any = []

  constructor(
    private _appts: AppTranslateService,
    public matDialogRef: MatDialogRef<AboutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public _auth: AuthService,
    private _matDialog: MatDialog
  ) {
    this.dialogTitle = "Acerca de";
    this.disabled = true;
    this.aboutInfo = this._auth.aboutInfo;
    this.dependences = this.aboutInfo.dependences || [];
  }

  ngOnInit() {}
}
