const getFile = async() => {
    return await fetch('assets/i18n/fr.json')
    .then(response => response.json())
    .then((info: any) => {
    return  info.data;
    })
}
export const locale =  {
    lang: 'fr',
    data: getFile()
};
