<div fxLayout="column" fxFlex fusePerfectScrollbar class="accent-50">
    <mat-list class="date accent-900" cdkFocusRegionStart style="width:100% !important">
        <div class="secondary-text mat-display-1 mb-0 p-16">
            <div class="mb-12 title">
              {{ 'layout.toolbar.quickPanel.title' | translate }}  
            </div>
        </div>
    </mat-list>

    <mat-divider cdkFocusRegionEnd></mat-divider>

    <mat-list *ngIf="_notifications.notifications.length === 0">
        <mat-list-item>
            <h3 matLine> {{'layout.toolbar.quickPanel.message' | translate }}  </h3>
        </mat-list-item>
    </mat-list>

    <mat-list>
        <mat-card class="example-card accent-100" *ngFor="let notif of _notifications.notifications" matRipple [@slideInTop]>
            <mat-card-header>
                <img mat-card-avatar [src]="notif.sender_imageUrl" onerror="this.src='/assets/images/avatars/profile.jpg'">
                <mat-card-title>{{notif.sender_fullname}}</mat-card-title>
                <mat-card-subtitle>{{notif.sender_subject}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div [innerHTML]="notif.sender_message" (click)="$event.stopPropagation()"></div>
            </mat-card-content>
            <mat-card-actions align="end">
                <button mat-button class="time">{{ viewDate(notif.sender_datetime) }}</button>
                <button mat-button class="okey accent" (click)="markToReaded(notif.message_id)"> {{ 'layout.toolbar.quickPanel.button' | translate }}  </button>
            </mat-card-actions>
        </mat-card>
    </mat-list>


</div>