import { environment } from './../environments/environment';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';

import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { FakeDbService } from 'app/fake-db/fake-db.service';

import { fuseConfig } from 'app/fuse-config';



// Sockets
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';





const config: SocketIoConfig = {
    url: environment.api_server ,
    options: {}
  };

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';


import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';


const appRoutes: Routes = [
    {
      path: 'apps',
      loadChildren: () => import('./main/apps/apps.module').then(m => m.AppsModule),
      // canActivate: [SecurityGuard]
    },
    {
      path: 'modules',
      loadChildren: () => import('./main/modules/modules.module').then(m => m.ModulesModule),
    },
    {
      path: 'pages',
      loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
    },
    {
      path: '**',
      redirectTo: '/pages/auth/login',
    },
  ];
  
@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),
       
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay             : 0,
            passThruUnknownUrl: true
        }),
        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        MatInputModule,
        MatFormFieldModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        SampleModule,
        SocketIoModule.forRoot(config),
        TranslateModule
    ],
    bootstrap   : [
        AppComponent
        
    ],
    providers:[
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {disableClose: true,hasBackdrop:true}}
    ]
})
export class AppModule {}
