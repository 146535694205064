import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { FuseConfigService } from "@fuse/services/config.service";
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from "app/navigation/navigation";
import { AuthService } from "app/main/services/auth.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { DialogComponent } from "../dialog/dialog.component";
import { Router } from "@angular/router";
import { UsersService } from "app/main/apps/contacts/users.service";
import { FormGroup } from "@angular/forms";
import { MyProfileFormDialogComponent } from "../../../main/apps/my-profile/my-profile.component";
import { DataService } from "../../../main/services/data.service";
import { NotificationsService } from "../../../main/services/notifications.service";
import { Location } from "@angular/common";
import { FunctionsService } from "../../../main/services/functions.service";
import { AppTranslateService } from "app/main/services/translate.service";
import { SocketService } from "app/main/services/socket.service";
import { AboutDialogComponent } from "app/main/apps/about/about.component";

@Component({
  selector: "toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any = [];
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  dialogRef: any;
  authType: string = "";
  isDefault: boolean = false;
  allowSetLanguage: boolean = false;
  langListConfig: any = [];

  // Private
  private _unsubscribeAll: Subject<any>;
  navbar_toggle_button: boolean;
  isSidebarLocked: boolean;
  locked: boolean;
  sidebarOpen: boolean;
  private sidebarSubscription: Subscription;
  isSmallScreen = false;


  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {TranslateService} _translateService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _translateService: TranslateService,
    public dialog: MatDialog,
    public _router: Router,
    private _matDialog: MatDialog,
    public _auth: AuthService,
    private _contactsService: UsersService,
    private _ds: DataService,
    public notifications: NotificationsService,
    private location: Location,
    private _functions: FunctionsService,
    private _appts: AppTranslateService,
    private breakpointObserver: BreakpointObserver
  ) {
    // Set the defaults
    this.userStatusOptions = [
      {
        title: "Online",
        icon: "icon-checkbox-marked-circle",
        color: "#4CAF50",
      },
      {
        title: "Away",
        icon: "icon-clock",
        color: "#FFC107",
      },
      {
        title: "Do not Disturb",
        icon: "icon-minus-circle",
        color: "#F44336",
      },
      {
        title: "Invisible",
        icon: "icon-checkbox-blank-circle-outline",
        color: "#BDBDBD",
      },
      {
        title: "Offline",
        icon: "icon-checkbox-blank-circle-outline",
        color: "#616161",
      },
    ];

    this._ds.menu.subscribe((menu) => {
      this.navigation = menu;
    });

    this.languages = this._appts.getLanguages();

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {

    this.isSidebarLocked = false;
    this.breakpointObserver
    .observe([Breakpoints.Small, Breakpoints.XSmall])
    .subscribe((result) => {
        this.isSmallScreen = result.matches;
        this.isSidebarLocked = false;

    });
    this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.XLarge])
    .subscribe((result) => {
        this.isSidebarLocked = false;
        this._fuseSidebarService.getSidebar("navbar-main").close();
        this._fuseSidebarService.setIsSidebarLocked(false)

    });
    this.sidebarSubscription = this._fuseSidebarService.isSidebarLocked$.subscribe(
      (isSidebarLocked) => {
        this.isSidebarLocked = isSidebarLocked;
      }
    );

    this.navigation = navigation;
    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === "top";
        this.rightNavbar = settings.layout.navbar.position === "right";
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });
     if(this._fuseSidebarService.getSidebar('navbar-main').isLockedOpen == true) {
      this.navbar_toggle_button = true
     } else {
      this.navbar_toggle_button = false
     }
    this.languages = this._appts.getLanguages();

    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, {
      id: this._translateService.currentLang,
    });

    this._auth.getAuthType().then((response: any) => {
      if (response.type) {
        this.authType = response.type;
      }
      if (response.language) {
        const saveLang: any = localStorage.getItem("lang")
          ? JSON.parse(localStorage.getItem("lang"))
          : undefined;

        if (saveLang !== undefined) {
          if (saveLang["id"]) {
            this._appts.setLanguage(saveLang["id"], true);
          } else {
            this._appts.setLanguage(saveLang, true);
          }
        } else {
          this._appts.setLanguage(response.language.defaultLanguage, true);
        }

        if (response.language.languages !== undefined) {
          this.langListConfig = response.language.languages;
          const resultado: any = this.languages.filter((obj) =>
            this.langListConfig.includes(obj.id)
          );
          this.languages = resultado;
          if (
            resultado.find((v) => v.id === this._appts.defaultLanguage) ===
            undefined
          ) {
            this._appts.defaultLanguage = resultado[0].id;
            this._appts.setLanguage(response.language.defaultLanguage, true);
          }
        }

        // // this._appts.setLanguage(response.language.defaultLanguage);
        this._appts.setAllowSetLanguage(
          response.language.allowSetLanguage || false
        );
        this.allowSetLanguage = this._appts.allowSetLanguage;

        // // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {
          id: this._translateService.currentLang,
        });
      }
    });

    this._appts.onLanguageChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((lang: any) => {
        this._translateService.use(lang.id ? lang.id : lang);
        this.selectedLanguage = _.find(this.languages, {
          id: this._translateService.currentLang,
        });
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next("");
    this._unsubscribeAll.complete();
    this.sidebarSubscription.unsubscribe();
  }

  updateSetting(value) {
    this._auth.updateUserSetting("hidden-sidebar", value);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
   
    if (key === 'navbar-main') {
      this._fuseSidebarService.getSidebar(key).toggleOpen();
   
      // Si el sidebar no está bloqueado, ajustamos la variable `sidebarOpen`
   
   
     
    }
  }
  

  /**
   * Search
   *
   * @param value
   */
  search(value): void {
    // Do your search here...
  }

  viewAbout(): void {
    this.dialogRef = this._matDialog.open(AboutDialogComponent, {
        panelClass: 'about-dialog',
        data      : {
            action: 'view'
        }
    });
}

  updateDefaultPage() {
    this._auth
      .updateUserSetting("default-feature", this.location.path())
      .then((resp: any) => {
        if (resp.ok) {
          this._auth.defaultFeature = resp.data.response.value;
          this._auth.getSettings();
          this._auth.isDefault = true;
          // Esta página ha sido configurada como aplicación por defecto correctamente.
          this._functions.notify(
            this._appts.translate("layout.toolbar.defaultPageOkMessage"),
            4000
          );
        } else {
          //No fue posible llevar a cabo esta configuración.
          this._functions.notify(
            this._appts.translate(`layout.toolbar.defaultPageNokMessage`),
            4000
          );
        }
      });
  }

  closeSession(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    //¿Seguro que deseas cerrar tu sesión?
    dialogConfig.data = {
      title:
        this._appts.translate("layout.toolbar.defaultPageSaludo") +
        " " +
        this._auth.user.name.split(" ")[0], // Hola
      message: this._appts.translate("layout.toolbar.defaultPageConfirm"),
      options: [
        {
          value: "ok",
          text: this._appts.translate("layout.toolbar.cerrarSesion"),
          icon: "exit_to_app",
          color: "accent",
        }, // Cerrar sesion
        {
          value: "nok",
          text: this._appts.translate("layout.toolbar.defaultPageNoConfirm"),
          icon: "",
          color: "navy",
        }, // No, Gracias
      ],
    };
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === "ok") {
        this._auth.closeSession();
      }
    });
  }

  viewProfile(): void {
    this.dialogRef = this._matDialog.open(MyProfileFormDialogComponent, {
      panelClass: "my-profile-form-dialog",
      data: {
        action: "edit",
      },
    });

    this.dialogRef.afterClosed().subscribe((response: FormGroup) => {
      if (!response) {
        return;
      }
      this._contactsService
        .updateProfile(response[0].value)
        .then((resp: any) => {
          if (resp.ok) {
            if (resp.data.response.ok) {
              this._auth.user.name = resp.data.response.name;
              this._auth.user.lastName = resp.data.response.lastName;
              this._auth.user.notes = resp.data.response.notes;
            }
          }
        });
    });
  }

  changePassword(): void {
    this._router.navigateByUrl("/pages/auth/reset-password");
  }

  /**
   * Set the language
   *
   * @param lang
   */
  setLanguage(lang): void {
    this._appts.setLanguage(lang);
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    // this._translateService.use(lang.id);
  }


}
