export const environment = {
    production: false,
    hmr       : false,
    api_server: 'https://api.forms.itesic.cl/',
    system_identifier: '2A721AB7-224F-4928-8AFB-B674FF823DA7',
    allowImageCompressor: true,
    modules: {
        forms: {
            endpoint: 'modules/forms/reports',
            inputEndpoint: 'modules/forms/imports',
            urlGenerateShortcutTemplates: '/modules/forms/pages/frms/shortcut/',
            urlGenerateShortcutList: '/modules/forms/lists/'
        }
    },
    mapbox: {
        token: 'pk.eyJ1IjoidnJ1YmlsYXIiLCJhIjoiY2xzMGhpNGJqMDB1bDJqbnF6c3NldjFrNyJ9.HSOBbHQ1fxE7MPA1P4wIDw'
    }
};

