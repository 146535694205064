const getAboutData = async () => {
    return await fetch('assets/misc/about.json')
    .then(response => response.json())
    .then((info: any) => {
    return  info;
    })
}

export const about =  {
    data: getAboutData()
};
